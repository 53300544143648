import { useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/website-logo.svg";
import menu from "../../images/open-menu.svg";
import "./Header.css";
import { MenuContext } from "../../contexts/MenuContext";

import Menu from "../Menu/Menu";

const Header = () => {
  const { menuOpen, openMenu, closeMenu } = useContext(MenuContext);

  const handleMenu = () => {
    if (menuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  return (
    <header className="header">
      <div>
        <NavLink to="/" onClick={closeMenu}>
          <img src={logo} className="header-logo" alt="logo" />
        </NavLink>
      </div>
      <div className="header-buttons">
        <button className="header-button" type="text" onClick={handleMenu}>
          <img className="header-nav" src={menu} alt="menu" />
        </button>
        {menuOpen && <Menu onClick={handleMenu} />}

        <button
          className="header-button"
          id="emailButton"
          onClick={() => {
            window.location.href =
              "mailto:sheena.irvin.sli@gmail.com?subject=Let's Talk🤘&body=Hi Sheena,%0D%0A%0D%0AI'd like to discuss...";
          }}
        >
          Let's Talk
        </button>
      </div>
    </header>
  );
};

export default Header;
