import "./Preloader.css";
import React from "react";
import Lottie from "lottie-react";
import preloaderAnimation from "../Preloader/preloader.json";

const Preloader = () => {
  return (
    <div className="preloader">
      <Lottie
        animationData={preloaderAnimation}
        loop={true}
        speed={3}
        className="preloader-image"
      />
      <div className="preloader-text">L o a d i n g ...</div>
    </div>
  );
};

export default Preloader;
