import "./Projects.css";

import ProjectCard from "../ProjectCard/ProjectCard";
import projectList from "../../utils/constants";

const Projects = () => {
  return (
    <section id="project-section" className="project_section">
      <h2 className="project_header">Projects</h2>
      <div className="project_items">
        {projectList.map((item) => (
          <ProjectCard item={item} key={item._id} />
        ))}
      </div>
    </section>
  );
};

export default Projects;
