import "./Main.css";
import SplineDesign from "../../SplineDesign/SplineDesign";

const Main = () => {
  return (
    <main className="main">
      <SplineDesign />
    </main>
  );
};

export default Main;
