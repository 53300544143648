import "./App.css";

import Preloader from "../Preloader/Preloader";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Projects from "../Projects/Projects";
import About from "../About/About";
import Footer from "../Footer/Footer";
import { Switch, Route } from "react-router-dom/cjs/react-router-dom.min";

import { MenuContext } from "../../contexts/MenuContext";
import { useState, useEffect } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  //esc and overlay menu close
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        closeMenu();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    const handleOverlay = (e) => {
      if (e.target.classList.contains("menu")) {
        closeMenu();
      }
    };
    document.addEventListener("click", handleOverlay);
    return () => document.removeEventListener("click", handleOverlay);
  }, []);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Check local storage for preloader flag
  useEffect(() => {
    const preloaderShown = localStorage.getItem("preloaderShown");
    if (!preloaderShown) {
      setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem("preloaderShown", "true");
      }, 3000); // seconds delay
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <div>
      <MenuContext.Provider value={{ menuOpen, openMenu, closeMenu }}>
        <Header />
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
        <Footer />
      </MenuContext.Provider>
    </div>
  );
}

export default App;
