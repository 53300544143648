import "./Footer.css";
import LinkedinIcon from "../../images/linkedin-white.svg";
import GithubIcon from "../../images/github-white.svg";
import LinkedinIconHover from "../../images/linkedin.svg";
import GithubIconHover from "../../images/github.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p className="footer__text">
        All rights reserved. &#169; Sheena Irvin {currentYear}
      </p>

      <div className="footer__links">
        <div className="footer__buttons">
          <Link to="/">
            <button className="footer__button" type="text">
              Home
            </button>
          </Link>
        </div>

        <div className="footer__icons">
          <a
            href="https://www.github.com/sheenasli"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GithubIcon}
              className="footer__icon-button"
              alt="Github Icon"
              onMouseOver={(e) => (e.currentTarget.src = GithubIconHover)}
              onMouseOut={(e) => (e.currentTarget.src = GithubIcon)}
            />
          </a>
          <a
            href="https://www.linkedin.com/in/sheena-irvin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedinIcon}
              className="footer__icon-button"
              alt="LinkedIn Icon"
              onMouseOver={(e) => (e.currentTarget.src = LinkedinIconHover)}
              onMouseOut={(e) => (e.currentTarget.src = LinkedinIcon)}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
