import NewsExplorerImage from "../images/NewsExplorer.png";
import WtwrImage from "../images/WtwrApp.png";
import AroundTheUsImage from "../images/AroundTheUS.png";
import LibraryImage from "../images/Library.png";
import CoffeeShopImage from "../images/Coffeeshop.png";

const projectList = [
  {
    _id: 0,
    title: "News Explorer App",
    image: NewsExplorerImage,
    about:
      "A full-stack application that offers a service where users can search for news articles from various sources and save them to their profiles. User is able to interact with their saved articles on the saved articles page and delete them.",
    languages:
      "React, JavaScript, CSS, Node.js, Express.js, MongoDB, API(External & Internal)",
    website: "https://www.newsexplorer.crabdance.com/",
    github: "https://github.com/sheenasli/news-explorer-frontend",
  },
  {
    _id: 1,
    title: "WTWR (What to Wear React) App",
    image: WtwrImage,
    about:
      "A full-stack application that responds with the daily weather for a specific city and suggests suitable clothings item/s to the user based on the weather conditions.",
    languages:
      "React, JavaScript, CSS, Node.js, Express.js, API(External & Internal)",
    website: "https://wtwr.switchestudio.com",
    github: "https://github.com/sheenasli/se_project_react",
  },
  {
    _id: 2,
    title: "Around the U.S. App",
    image: AroundTheUsImage,
    about:
      "A fully functioning interactive page where users can add and remove images, like and unlike posts, and make updates to their profile information.",
    languages: "JavaScript, HTML, CSS",
    website: "https://sheenasli.github.io/se_project_aroundtheus/",
    github: "https://github.com/sheenasli/se_project_aroundtheus",
  },
  {
    _id: 3,
    title: "Practicum Coffeeshop",
    image: CoffeeShopImage,
    about:
      "A fully functioning page with recipes, menus, reservations, and about section for a coffee shop.",
    languages: "HTML, CSS",
    website: "https://sheenasli.github.io/se_project_coffeeshop/",
    github: "https://github.com/sheenasli/se_project_coffeeshop",
  },
  {
    _id: 4,
    title: "Practicum Library",
    image: LibraryImage,
    about:
      "A simple landing page with a staff pick, events, become a member, about, and contact section for a library.",
    languages: "HTML, CSS",
    website: "https://sheenasli.github.io/se_project_library/",
    github: "https://github.com/sheenasli/se_project_library",
  },
];

export default projectList;
