import React from "react";
import Spline from "@splinetool/react-spline";

import "./SplineDesign.css";

const SplineDesign = () => {
  return (
    <div className="spline-container">
      <Spline
        scene="https://prod.spline.design/tFLoEUFOGPNzaN2z/scene.splinecode"
        className="spline"
      />
    </div>
  );
};

export default SplineDesign;
