import "./ProjectCard.css";

import CardGithubIcon from "../../images/github-grey.svg";
import CardGithubIconHover from "../../images/project-github-icon-hover.svg";
import CardSiteIcon from "../../images/site-grey.svg";
import CardSiteIconHover from "../../images/site-hover.svg";

const ProjectCard = ({ item }) => {
  return (
    <div className="project_container">
      <img className="project_image" src={item.image} alt={item.title} />

      <div className="project_content">
        <div className="project_title-wrapper">
          <h3 className="project_title">{item.title}</h3>
          <div className="project_card-links">
            <a href={item.website} target="_blank" rel="noopener noreferrer">
              <img
                src={CardSiteIcon}
                className="project__icon-button"
                alt="Project Website Icon"
                onMouseOver={(e) => (e.currentTarget.src = CardSiteIconHover)}
                onMouseOut={(e) => (e.currentTarget.src = CardSiteIcon)}
              />
            </a>
            <a href={item.github} target="_blank" rel="noopener noreferrer">
              <img
                src={CardGithubIcon}
                className="project__icon-button project__icon-button_github"
                alt="Project Github Icon"
                onMouseOver={(e) => (e.currentTarget.src = CardGithubIconHover)}
                onMouseOut={(e) => (e.currentTarget.src = CardGithubIcon)}
              />
            </a>
          </div>
        </div>
        <p className="project_description">{item.about}</p>
        <p className="project_description">Tech used:&nbsp; {item.languages}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
