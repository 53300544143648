import "./About.css";
import AvatarImage from "../../images/ProfileCompressed.png";

const About = () => {
  return (
    <section className="about" id="about-section">
      <div className="about__container">
        <div className="about__avatar-image">
          <img src={AvatarImage} alt="Author" />
        </div>
        <div className="about__text">
          <h2 className="about__text-author">A Little About Me</h2>
          <p className="about__text-bio">
            I'm a store manager/banker/teacher turned full-stack developer,
            embracing the exhilarating world of code. My life has been marked by
            ever-changing environments where stepping out of my comfort zone has
            become the norm. Growing up in a military family, teaching English
            in Korea, and banking at JP Morgan Chase, continual learning and
            change have always been a part of my life.
            <br></br>
            <br></br>
            In my quest for personal growth and societal impact, I discovered
            software engineering. It provides a creative outlet where I can
            leverage the solution-oriented approach and client service mantras
            honed over years in various industries. Although new to the tech
            industry, I've been completely consumed by the potential that this
            skill set and field offer.
            <br></br>
            <br></br>
            Long-term, my vision is not only to provide leading-edge,
            client-centric solutions but also to become an established member of
            the tech community, paving the way for other women. It's about
            breaking barriers, building bridges, and ensuring a more inclusive
            and impactful tech landscape. Let's code the future together!
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
