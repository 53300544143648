import "./Menu.css";
import { NavLink } from "react-router-dom";

const Menu = ({ onClick }) => {
  return (
    <nav className="menu">
      <NavLink
        to="/projects"
        className="menu-button_first"
        type="text"
        onClick={onClick}
        // activeClassName="header__button-active"
      >
        Projects
      </NavLink>

      <NavLink
        to="/about"
        className="menu-button_second"
        type="text"
        onClick={onClick}
        // activeClassName="header__button-active"
      >
        About
      </NavLink>
    </nav>
  );
};

export default Menu;
